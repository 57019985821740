import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import SingleVideo from './SingleVideo';



export default function SelectedProjectsList({ projects}) {
  

  console.log(projects[0].featuredVideo.moodImage.localFile)

 

  return (
    <>
    <div className="container-fluid  pb-10 pb-md-20">
    {projects.map((project, index) => (
       <Link to={project.uri} className="d-block_" >

      <div key={project.id} className="row mb-10 mb-md-20">
        <h2   className="fade-in_ h1 mb-0" dangerouslySetInnerHTML={{ __html: project.title + ' ↘' }} data-scroll="out" data-splitting="" />
        {/* <div data-scroll="" className="fade-in small">{project.projektDetails.subtitle}</div> */}
        <div  className={`col-md-6 p-0 d-none d-md-block ${index % 2 ? 'order-md-2' : ''}`} style={{ backgroundColor: project.projektDetails.color}}>

            { project.featuredVideo.moodImage  && 
              <GatsbyImage  image={getImage(project.featuredVideo.moodImage.localFile)} alt={project.title.replace('<br>' , '')}  />
            }


        </div>

        <div className={`col-md-6  p-0 `}>
          <div className="img-link h-100_" style={{ backgroundColor: project.projektDetails.color}}>
         
              { project.featuredVideo.featuredVideo != null && 
                    <SingleVideo src={project.featuredVideo.featuredVideo.mediaItemUrl} ratio={project.featuredVideo.featuredVideo.height / project.featuredVideo.featuredVideo.width * 100 } />
              }

              { project.featuredVideo.featuredVideo == null && 
                <GatsbyImage  image={getImage(project.featuredImage.node.localFile)} alt={project.title.replace('<br>' , '')} objectFit="contain" className="img-browser_"/>
              }
           
          </div>
          
          <div>
          
          { /*project.projektDetails.abstract != null &&
          <p data-scroll="" >
            {project.projektDetails.abstract}
          </p>
          */ }
            {/*
          <Link to={project.uri} className="link-detail small">
            <span className="visually-hidden">{project.title}</span> <span className="text" data-text="Details &rarr;">Details &rarr;</span>
          </Link>
            */ }
          </div>
        </div>
      </div>
      </Link>
    ))}
    </div>
    </>
  )
}