import React, {useEffect} from 'react'
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import SelectedProjectsList from '../components/SelectedProjectsList';
import Splitting from "splitting";
import ScrollOut from "scroll-out";
//import { Canvas } from "react-three-fiber";
//import Obfuscate from 'react-obfuscate';

import Seo from "../components/Seo"
import Greeting from "../components/Greeting"


// markup
const IndexPage = ({data}) => {
  const {
    wpPage: { seo, title, content, selectedProjects , featuredImage },
  } = data

  

  //console.log(content)

  useEffect(() => {

    // do something
    
    const scroll = ScrollOut({once:true});
    
    setTimeout(() => {
      Splitting();
      scroll.update();
    },300);

    return () => {
      scroll.teardown();
    }
  }, []);

  return (
    <>
     <Seo title={seo.title} description={seo.metaDesc} />
     <>
     <div key="kjsdfl" className="container-fluid mb-5 mb-md-10">
    <div className="position-relative">

          { featuredImage && 
            <GatsbyImage  image={getImage(featuredImage.node.localFile)} alt="Fint Digital Design" className="mb-5 mb-md-10" />
          }
      <div className="big position-absolute" data-scroll="out" data-splitting="">Fint</div>
    </div>

      <div className="col-md-6 offset-md-6 col-xl-5 col-xxl-7_ mb-10 mb-md-20 fade-in"  data-scroll="out">
        <div id="greeting" className="small"></div>
        <h1 className="normal mb-0 d-inline">
        <Greeting />
          { title} 
        </h1>
        <div className="h2   d-inline"  dangerouslySetInnerHTML={{ __html: content }} />
      </div>
      <div className="small">● Ausgewählte Projekte</div>
     </div>
      <SelectedProjectsList projects={selectedProjects.featured} />

      <div className="container-fluid">
        <div className="col-md-6 offset-md-6 col-xl-5">
          <Link to={'/angebot'} className="h1" state="dark"> 
            Unser Angebot <br/>anschauen ↘
          </Link>
          
        </div>
      </div>
    </>
    </>
  )
}


export const query = graphql`
   {
    wpPage(databaseId: {eq: 1400}) {
      title
      content
      seo {
        title
        metaDesc
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    
      selectedProjects {
        featured {
          ... on WpPost {
            id
            databaseId
            title
            uri
            projektDetails {
              subtitle
              abstract
              color
            }
            featuredVideo {
              featuredVideo {
                mediaItemUrl
                width
                height
              }
              moodImage {
               
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 1 
                        transformOptions: {fit:COVER, cropFocus: NORTH}
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                      )
                    }
                  }
               
              }
            }
      

            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 600
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                      transformOptions: {fit:CONTAIN}
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
