import React, {useEffect, useState } from 'react';

export default function Greeting() {

    const [greetingState, setgreetingState] = useState();

    useEffect(() => {
      const hour = new Date().getHours();

      let greeting = "Hallo";
     
      if (hour >= 5 && hour < 11) 
        greeting = "Guten Morgen";
      else if (hour > 10 && hour < 18)
        greeting = "Hallo";
      else if (hour > 17 && hour < 22)
        greeting = "Guten Abend";
      else if (hour > 21 || hour < 4)
        greeting = "Gute Nacht";
      
      greeting += ', ';

      setgreetingState(greeting);

    }, []);
 

  return (
    <>
    <span>{greetingState}</span>
    </>
  )
}